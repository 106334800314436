
body {
  font-family: 'Nunito', sans-serif;
  color: #161c2d;
  font-size: 18px;
  overflow-x:hidden;
}
p {
  letter-spacing: 0.8px;
  line-height: 1.6;
}
.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.8px;
    transition: all 0.3s;
    font-weight: 700;
}

.btn-custom, .btn-outline-white:hover {
  color: #fff;
  background: #2f55d4;
  border: 1px solid #2f55d4;
}

.btn-custom:hover {
  background-color: #2c48a5;
  color: #fff;
}

.btn-outline-white {
  background-color: #ffffff;
  color: #2f55d4;
  border-color: #2f55d4;
}
.rounded {
  border-radius: 10px !important; 
}
.text-muted {
  color: #7d899b !important;
}
.home-wrapper:before {
  content: "";
  position: absolute;
  bottom: 25rem;
  right: 60rem;
  width: 90rem;
  height: 35rem;
  border-radius: 17rem;
  transform: rotate(45deg);
  background-color: #f8f9fc;
}
.logo {
  font-weight: 700;
  font-size: 28px;
  padding: 3px 0;
  letter-spacing: 1px;
  line-height: 68px;
}
.bg-light {
  background: #f8f9fc !important;
}
/*******HOME*******/
.bg-home-half {
  position: relative;
  padding: 150px 0;
  background: #fff;
}
.danger {
  color: #e43f52;
}
.home-danger {
  font-size: 45px;
}
.text-custom {
  color: #2f55d4;
}
.home-center {
  display: table;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.section-title h3 {
  font-size: 28px;
  font-weight: 700;
}

.section-title h2 {
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
}
.section-title .line-bot {
  display: inline-block;
  width: 50px;
  height: 2px;
  border-radius: 30px;
  margin: 15px 0 10px;
  background: #5a5757;
}
.section {
  padding: 80px 0 30px;
  position: relative;
}
.section-two {
  padding: 80px 0;
  position: relative;
}

/******DEMOS*******/
.padding-ten {
  padding-left: 10%;
  padding-right: 10%;
}
.demo-box {
  padding: 20px 0 50px 0; 
  text-align: center;
}

.demo-box img {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
            transition: all 400ms ease-in-out;
}

.demo-box img:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);    
    transform: translateY(-10px);
}

.demo-box h4 {
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
}

.demo-box h4 span {
text-transform: capitalize;
    font-weight: normal;
    font-size: 80%;
}

.footer-three {
  padding: 40px 0;
  position: relative;
}

.copy-rights {
  font-size: 15px;
  color: #adb5bd !important;
}

.border {
  height: 4px;
  width: 60px;
  border-radius: 5px;
  margin: 0 auto 30px;
  background-color: #2f55d4;
}

.title {
  margin-bottom: 15px;
  font-size: 22px;
}

.shadow {
  box-shadow: 0 0 3px rgba(0,0,0,.15) !important;
}
.mover {
  animation: mover 1s infinite alternate;
}
@keyframes mover {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
  100% {
      transform: translateY(35px);
  }
}
/* Feature */

.key-feature {
  transition: all 0.2s ease;
}
.key-feature .icon {
    height: 40px;
    width: 40px;
    line-height: 40px;
    background: lighten(#2f55d4, 45%);
    font-size: 24px;
}

.key-feature:hover {
    transform: scale(1.05);
}
.key-feature .content .title {
  font-size: 18px;
  font-weight: 700;
}

.bg-dark {
  background-color: #202942 !important;
}

/* back to top */
.back-to-top {
    z-index: 99; 
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 33px;
    height: 33px;
    line-height: 33px;
    display: none;
    color: #161c2d;
    background-color: #ced4da;
    transition: all 0.5s ease; 
}
.back-to-top i {
  font-size: 16px;
}

.back-to-top:hover {
  background-color: #2f55d4;
  border-color: #2f55d4 !important;
  color: #ffffff;
}

@media (max-width: 767px) {
  .bg-home-half {
    padding: 100px 0;
  }
}